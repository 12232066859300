<script setup lang="ts">
const links = [
  {
    label: '礼物',
    icon: 'material-symbols:featured-seasonal-and-gifts-rounded',
    to: '/dashboard/order',
  },
  {
    label: '地址',
    icon: 'material-symbols:add-location-outline-rounded',
    to: '/dashboard/address',
  },
]
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <UDashboardNavbar :ui="{ left: 'flex-1' }">
        <template #left>
          <NuxtLink class="btn sm:btn-sm btn-ghost" to="/">
            <NuxtImg
              class="h-7 sm:h-6"
              src="1441947b49b2e92d64c51acceeaf993b.png"
              height="56"
              alt="Hanabi_Live"
            />
          </NuxtLink>
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <UDashboardSidebarLinks
          class="max-sm:space-y-1"
          :links
          :ui="{
            label: 'max-sm:text-lg',
            icon: { base: 'max-sm:size-6' },
          }"
        />

        <div class="flex-1" />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />
  </UDashboardLayout>
</template>

<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

const { onLogout, user } = useLoginStore()

const breakpoints = useBreakpoints(breakpointsTailwind)
const smallerThanSm = breakpoints.smaller('sm')

const items = [
  [{
    slot: 'account',
    label: '',
    disabled: true,
  }],
  [{
    label: '退出登录',
    icon: 'heroicons:arrow-left-on-rectangle',
    click: async () => {
      await onLogout()
      await navigateTo('/')
    },
  }],
]
</script>

<template>
  <UDropdown
    mode="hover"
    :items="items"
    :ui="{
      width: 'w-full',
      item: {
        size: 'max-sm:text-lg',
        disabled: 'cursor-text select-text',
        icon: { base: 'max-sm:size-7' },
      },
    }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :size="smallerThanSm ? 'sm' : 'xs'"
        :label="user?.name"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
        :ui="{ font: 'max-sm:text-xl' }"
      >
        <template #leading>
          <UAvatar
            :src="user?.face"
            :size="smallerThanSm ? 'xs' : '2xs'"
            referrerpolicy="same-origin"
          />
        </template>

        <template #trailing>
          <UIcon
            name="heroicons:ellipsis-vertical"
            class="size-7 sm:size-5 ml-auto"
          />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left max-sm:text-lg">
        <p>
          用户
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          UID:{{ user?.uid }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
